import mixpanel from "mixpanel-browser";

const MIXPANEL_PROJECT_ID = import.meta.env.VITE_MIXPANEL_PROJECT_ID;
const ENV = import.meta.env.VITE_ENV;


function init () {
    mixpanel.init(MIXPANEL_PROJECT_ID, {
        track_pageview: "url-with-path-and-query-string"
        });
      mixpanel.track(`Env: ${ENV}`);
}

function track(eventName, data) {
    mixpanel.track (eventName, data);
}

function login(userId) {
    track('login');
    mixpanel.identify(userId);
}

function logout() {
    track('logout');
    return mixpanel.reset();
}

export default {
    init, login, logout, track
};

