import { Button } from '@chakra-ui/react';
import React, { Component } from 'react';
import event from '../../lib/analytics';
import styles from "./ErrorBoundary.module.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    event('error', {errorInfo});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <main className={styles.main}>
          <div className={styles.content}>
            <img src='/sad-fishy.png' style={{maxHeight: '30vh', maxWidth: '30vw'}}/>
            { this.state.hasError.text ? 
              <h1>{this.state.hasError.text}</h1> : 
              <>
                <h1>Oops! something wen't wrong..</h1>
                <p>Please try again, and contact us to tell us what went wrong so we could fix it :)</p>
                <Button colorScheme='green' onClick={() => window.location.reload()} >Refresh</Button>
              </>
            }
          </div>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;