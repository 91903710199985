import { create } from 'zustand';

const useHeaderStore = create((set) => ({
  closeIconVisible: false,
  closeIconClicked: null,
  header: null,
  setHeader: header => set(state => ({header})),
  setCloseIconVisible: (visibility) => set((state) => ({ closeIconVisible: visibility })),
  setcloseIconClicked: callback => set({closeIconClicked: callback})
}));

export default useHeaderStore;