import { googleLogout } from '@react-oauth/google';
import { create } from 'zustand';
import api from '../lib/api';
import events from '../lib/events';
import useFishyStore from './fishys';
const PAYPAL_FISHY_PREMIUM_MONTHLY = import.meta.env.VITE_PAYPAL_FISHY_PREMIUM_MONTHLY;
const PAYPAL_FISHY_PREMIUM_YEARLY = import.meta.env.VITE_PAYPAL_FISHY_PREMIUM_YEARLY;
const PAYPAL_FISHY_ENTERPRISE_MONTHLY = import.meta.env.VITE_PAYPAL_FISHY_ENTERPRISE_MONTHLY;
const PAYPAL_FISHY_ENTERPRISE_YEARLY = import.meta.env.VITE_PAYPAL_FISHY_ENTERPRISE_YEARLY;

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
      atob(base64)
          .split('')
          .map((c) => {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
  );
  return JSON.parse(jsonPayload);
};

function _getPersonalAccount(serverUser) {
  return serverUser?.accounts.find (({account}) => account.tier !== TIERS.ENTERPRISE).account;
}

const useUserStore = create((set, get) => ({
  user: null,
  serverUser: null,
  selectedAccount: null,
  setSelectedAccount: accountId => set({selectedAccount: accountId}),
  getPersonalAccount: () => {
    const personalAccount = _getPersonalAccount(get().serverUser);
    return personalAccount;
  },
  loginSuccess: async (credentials) => {
    try {
      const parsedJwt = parseJwt(credentials?.credential);
      const user = {...{idToken: credentials}, ...parsedJwt};
      const serverUser = await api.userLoggedIn(credentials);
      const fishyStore = useFishyStore.getState();
      if (serverUser.data) {
        if (!fishyStore.aquariumId && !fishyStore.connectToken){
          fishyStore.setAquariumId(_getPersonalAccount(serverUser.data.user).aquariums[0]?.token);
          fishyStore.setConnectToken(serverUser.data.connectToken);
          fishyStore.setConnectedToOwnAquarium(true);
        }
        set({user, serverUser: serverUser.data.user, selectedAccount: serverUser.data.user?.accounts[0]?.account.id});
        events.login(serverUser.data.user.id);
        return true;
      }
      else {
        console.error(`loginSuccess handler error`);
        return false;
      }

    }
    catch (ex) {
      console.error(`loginSuccess handler error: ${ex}`);
      return false;
    }
  },
  getUser: async () => {
    try {
      const res = await api.getUser();
      set ({ serverUser: res.data });
    }
    catch (ex) {
      console.error (`Error fetching user: ${ex}`);
    }
  },
  logout: () => {
    googleLogout()
    events.logout()
    return set ({user: null});
  }
}));

export const TIERS = {
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
  ENTERPRISE: 'ENTERPRISE'
}


export const PRODUCTS = {
  PREMIUM_MONTHLY: {
    tier: TIERS.PREMIUM,
    // subscription: 'P-3D0386485D4494348M4CRZPY', old via api
    // subscription: 'P-7UR349438F619853LM4Q623I',
    subscription: PAYPAL_FISHY_PREMIUM_MONTHLY,
    description: 'Personal - Monthly',
    price: 5
  },
  PREMIUM_YEARLY: {
    tier: TIERS.PREMIUM,
    subscription: PAYPAL_FISHY_PREMIUM_YEARLY,
    description: 'Personal - Yearly (25% off)',
    price: 45,
  },
  ENTERPRISE_MONTHLY: {
    tier: TIERS.ENTERPRISE,
    subscription: PAYPAL_FISHY_ENTERPRISE_MONTHLY,
    description: 'Commercial - Monthly',
    price: 100
  },
  ENTERPRISE_YEARLY: {
    tier: TIERS.ENTERPRISE,
    subscription: PAYPAL_FISHY_ENTERPRISE_YEARLY,
    description: 'Commercial - Yearly (25% off)',
    price: 1080,
  }
}

export default useUserStore;