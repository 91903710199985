import { create } from 'zustand';
const halloweenSoundPool = [
  new Audio("/assets/audio/halloween1.mp3"),
  new Audio("/assets/audio/halloween2.mp3"),
  new Audio("/assets/audio/halloween3.mp3")
];

const useHallowenStore = create((set, get) => ({
  halloweenFishyUploaded: false,
  timeout: [],
  setHalloweenFishyUploaded: () =>  {
    get().timeout && clearTimeout(get().timeout)
    const random = Math.floor(Math.random() * 2.99);
    halloweenSoundPool[random].play();
    set({halloweenFishyUploaded: true});
    get().timeout = setTimeout(() => set({halloweenFishyUploaded: false}), 5000);
  }, 
}));

export default useHallowenStore;