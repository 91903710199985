import axios from 'axios';
import errors from '../errors/errors';
const baseURL = import.meta.env.VITE_API_URL;
const _axios = axios.create({
    baseURL,
    timeout: 5000,
});


const api = {
    _request(url, data, options) {
        return _axios(url, data, options);
    },
    validateAuth: () => {
        if (!_axios.defaults.headers.common['Authorization']) 
            throw errors.USER_NOT_LOGGED_IN;

        return api;
    },
    async getFishys (aquariumId) {
        return api._request({url: `/aquariums/${aquariumId}`});
    },
    async getFishysConnectToken (connectToken) {
        return api._request({url: `/aquariums/connect/${connectToken}`});
    },
    async uploadFishy (aquariumId, blob, maskUrl) {
        let formData = new FormData();
        formData.append('fishy', blob);
        formData.append('maskUrl', maskUrl)

        return api._request({
                url: `/aquariums/${aquariumId}/fishys`,
                data: formData,
                method: "post"
            });
    },
    async uploadFishyConnectToken (connectToken, blob, maskUrl) {
        let formData = new FormData();
        formData.append('fishy', blob);
        formData.append('maskUrl', maskUrl)

        return api._request({
                url: `/aquariums/connect/${connectToken}/fishys`,
                data: formData,
                method: "post"
            });
    },
    async userLoggedIn(auth) {
        const res = await api._request({
            url: `/auth/login`, 
            data: auth,
            method: "post",
        });

        if (res.status === 200 || res.status === 201) {
            _axios.defaults.headers.common['Authorization'] = `Bearer ${auth.credential}`;
        }

        return res;
    },
    async isAquariumExists(aquariumId) {
        return api._request({url: `/aquariums/${aquariumId}/exists`});
    },
    async isAquariumExistsConnectToken(connectToken) {
        return api._request({url: `/aquariums/connect/${connectToken}/exists`});
    },
    async createAccount(data) {
        return api.validateAuth()._request({
            url: '/account',
            data,
            method: 'post'
        });
    },
    async updateAccountName({accountId, name}) {
        return api.validateAuth()._request({
            url: `/account/${accountId}`,
            data: {name},
            method: 'patch'
        });
    },
    async registerSubscription ({accountId, subscriptionId}) {
        return api.validateAuth()._request({
            url: `/account/${accountId}/subscription`,
            data: { subscriptionId },
            method: 'post'
        });
    },
    async getUser() {
        return api.validateAuth()._request({url: `/users/activeUser`});
    },
    async buyMoreAquariums ({accountId, aquariumCount}) {
        return api.validateAuth()._request({
            url: `account/${accountId}/subscription`,
            method: 'patch',
            data: {aquariumCount}
        });
    },
    async deleteAquarium ({aquariumId}) {
        return api.validateAuth()._request({
            url: `/aquariums/${aquariumId}`,
            method: 'delete'
        })
    },
    async cancelPersonalSubscription({accountId}) {
        return api.validateAuth()._request({
            url: `account/${accountId}/subscription/personal`,
            method: 'delete'
        });
    }
}

export default api;
