import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import useHeaderStore from "../../../store/headerStore";
import styles from './header.module.scss';
const clickSound = new Audio('/assets/audio/click.wav');
clickSound.volume = 0.1;

export default function Header({menuClicked}) {

    const [closeIconVisible, closeIconClicked, header] = useHeaderStore(state => [state.closeIconVisible, state.closeIconClicked, state.header]);

    const _onClick = useCallback(() => {
        clickSound.play();
        closeIconClicked();
    })

    return (
        <header className={styles.header}>
            <FontAwesomeIcon icon={faBars} size="2x" className={styles.burger} onClick={() => menuClicked()}/>
                <h2 className={styles.headerText}>{header}</h2>
            { !closeIconVisible ? null :
                <FontAwesomeIcon icon={faXmark} size="2x" className={styles.burger} onClick={_onClick}/>
            }
        </header>
    )
}