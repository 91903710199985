import { Suspense, lazy, useEffect } from 'react';

const Upgrade = lazy(() => import('../pages/Upgrade/Upgrade'));
const Share = lazy(() => import('../pages/Share/Share'));
const Upload = lazy(() => import('../pages/Upload/Upload')) ;
const Capture = lazy(() => import('../pages/Upload/Capture/Capture'));
const Review = lazy(() => import('../pages/Upload/Review/Review'));
const PrintModal = lazy(() => import('../pages/Print/Print'));
const LoginModal = lazy(() => import('../pages/LoginModal/LoginModal'));
const Accounts = lazy(() => import('../pages/Account/Accounts'));
const Account = lazy(() => import('../pages/Account/Account'));
// import Account from '../pages/Account/Account';

import App from '../App';

import { Route, Routes } from "react-router-dom";
import Purchase from '../pages/Purchase/Purchase';

function FishyRoutes() {

  useEffect(() => {
    setTimeout(async () => {
      console.log('starting to load lazy routes');
      await import('../pages/Upload/Capture/Capture');
      await import('../pages/Upload/Review/Review');
      console.log('done loading lazy routes');
    }, 500)
  }, [])

  return (
        <Suspense fallback={<App loading/>}>
          <Routes>
            <Route path="/" >
                <Route index element={<App openMenu={true}/>} />
                <Route element={<App openMenu={false}/>} >
                  <Route path="/upload" element={<Upload />} />
                  <Route path="/capture" element={<Capture />} />
                  <Route path="/capturing" element={<Review />} />
                  <Route path="/print" element={<PrintModal />} />
                  <Route path="/login" element={<LoginModal />} />
                  <Route path="/upgrade" element={<Upgrade />} />
                  <Route path="/share" element={<Share />} />
                  <Route path="/account/:id" element={<Account />} />
                  <Route path="/account" element={<Accounts />} />
                  <Route path="/purchase" element={<Purchase />} />
                </Route>
            </Route>
            <Route path="/aquarium/:id" element={<App aquariumPath={true}/>} >
              <Route path="/aquarium/:id/upload" element={<Upload />} />
              <Route path="/aquarium/:id/capture" element={<Capture />} />
              <Route path="/aquarium/:id/capturing" element={<Review />} />
              <Route path="/aquarium/:id/print" element={<PrintModal />} />
              <Route path="/aquarium/:id/login" element={<LoginModal />} />
              <Route path="/aquarium/:id/upgrade" element={<Upgrade />} />
              <Route path="/aquarium/:id/share" element={<Share />} />
            </Route>
            <Route path="/c/:connectToken" element={<App />}>
              <Route path="/c/:connectToken/upload" element={<Upload />} />
              <Route path="/c/:connectToken/capture" element={<Capture />} />
              <Route path="/c/:connectToken/capturing" element={<Review />} />
              <Route path="/c/:connectToken/print" element={<PrintModal />} />
              <Route path="/c/:connectToken/login" element={<LoginModal />} />
              <Route path="/c/:connectToken/upgrade" element={<Upgrade />} />
              <Route path="/c/:connectToken/share" element={<Share />} />
            </Route>
            <Route path="*" element={<App />} />
          </Routes>
        </Suspense>
     
  )
}

export default FishyRoutes;