import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import "./lib/18n";
import events from './lib/events';
import FishyRoutes from './Routes/Routes';
import theme from './theme';
events.init();
const customTheme = extendTheme(theme);
const GOOGLE_CLIENT_ID = '89736342506-fn6p1unmlcv06tent75gfs3qbmkphn6g.apps.googleusercontent.com'
const PAYPAL_CLIENT_ID = import.meta.env.VITE_PAYPAL_CLIENT_ID;
// const PAYPAL_CLIENT_ID="AWTJqSFsFWAprYdrWve5eT0h0rQvF4MfIjkotAaNgTiITNqqFoOajezdUUtvAEJY1y3b9Z7i_b5pjonj" //sandbox
// const PAYPAL_CLIENT_ID="Ab6mRE2JSZMdEVnwH1SHpNgJQF39Kh2COQVJ4oG75Bs1Q42ImRPa0JSj0vjyrjLdu8oyHUc3xJ30yVzf" //live

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ChakraProvider theme={customTheme}>
        <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID, vault: true, intent: "subscription"}}>
          <Router>
            <FishyRoutes />
          </Router>
        </PayPalScriptProvider>
      </ChakraProvider>
    </GoogleOAuthProvider>
  // </React.StrictMode>,
)