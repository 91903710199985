import { Card, CardHeader, Input, Radio, RadioGroup, useToast } from "@chakra-ui/react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from 'react-router-dom';
import FullModalContent from "../../components/Modal/FullModalContent/FullModalContent.jsx";
import Modal from "../../components/Modal/Modal.jsx";
import api from "../../lib/api.js";
import useUserStore, { PRODUCTS, TIERS } from "../../store/userStore.js";
import styles from "./Purchase.module.scss";

function Purchase() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    const tier = searchParams.get('tier');
    const accountId = searchParams.get('accountId');
    const aquariums = Number(searchParams.get('aquariums'));
    const back = searchParams.get('back');
    const [serverUser, getUser] = useUserStore(state => [state.serverUser, state.getUser]);
    const toast = useToast();
    const filteredProducts = useMemo (() => {
        if (!tier) return PRODUCTS;
        return Object.fromEntries(Object.entries(PRODUCTS).filter (([key, p]) => p.tier === tier));
    }, [tier])
    const [selected, setSelected] = useState();
    const [aquariumCount, setAquariumCount] = useState(aquariums || 1);
    const [modalActive, setModalActive] = useState(false);

    const createSubscription = useCallback((data, actions) => {
        const subscription = actions.subscription.create({
            "plan_id": PRODUCTS[selected].subscription,
            "quantity": PRODUCTS[selected].tier === TIERS.PREMIUM ? 1 : aquariumCount
            });
        
        return subscription;
    }, [selected]);

    const onApprove = useCallback(async (data) => {
        const subscriptionId = data.subscriptionID;
        console.log (`[onApprove] subscription went through: ${subscriptionId}. Updating service`);
        try {
            const response = await api.registerSubscription({accountId, subscriptionId});
            toast({
                description: t('registeredSubscriptionSuccessfuly'),
                status: 'success',
                duration: 4000,
              });
            getUser();
            if (back)
                navigate (-1);
            else
                navigate(`/account/${accountId}`, { replace: true });
        }
        catch (ex) {
            console.error (`[onApprove] Error registering the subscription '${subscriptionId} to account '${accountId}'': ${ex}`);
            toast({
                description: t('errorRegisteringSubscription'),
                status: 'error',
                duration: 4000,
              })
        }

    }, [accountId]);

    // if not logged in, navigate to login
    useEffect(() => {
        if (!serverUser) {
            console.log(`not logged in, navigating to login first`);
            navigate('/login');
            return;
        }

        if (!accountId) {
            throw {code: "ACCOUNT_MISSING", text: `${t('account')} ${accountId} ${t(`notFound`)}`};
        }

        setModalActive(true);

        return () => {
            setModalActive(false);
        }
    }, [])

    return (
        <Modal active={modalActive} dropShadow={false} closeIconClick={() => navigate(-1)} header={t('purchaseSubscription')}>
            <FullModalContent>
                <div className={styles.purchase}>
                    <RadioGroup value={selected} onChange={(value) => {setSelected(value)}} variant='outline' size="lg">
                        <div className={styles.productList}>
                            {Object.entries(filteredProducts).map(([key, p]) => <Card key={key} className={styles.card}>
                                <CardHeader className={`${styles.product}${selected === key ? ` ${styles.selected}` : ''}`}>
                                    <div className={styles.productName}>
                                        <Radio value={key}>{p.description}</Radio>
                                    </div>
                                    {p.tier !== TIERS.ENTERPRISE ? null :
                                        <Input className={styles.aquariumCount} name="aquariumCount" type="number" value={aquariumCount} onChange={e => setAquariumCount(Number(e.target.value))}></Input>
                                    }
                                    <div className={`${styles.price} ${p.tier !== TIERS.ENTERPRISE ? styles.alignEnd : ''}`}>{(aquariumCount * p.price).toLocaleString('en-US', {style:"currency", currency: 'USD', maximumFractionDigits:0})}</div>
                                </CardHeader>
                            </Card>)
                            }
                        </div>
                    </RadioGroup>
                    <div className={styles.total}>
                        <PayPalButtons
                            key={`${selected}-${aquariumCount}`} 
                            style={{ layout: "vertical", color: 'blue', shape: 'rect' }} 
                            createSubscription={createSubscription}
                            onApprove={onApprove}
                            onError={err => console.log(err)}
                            disabled={!selected}
                        />
                    </div>
                </div>
            </FullModalContent>
        </Modal>
    );
}
export default Purchase
