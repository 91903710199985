
const customInputStyleVariant = {
    borderColor: "white", // Default border color
    backgroundColor: "rgba(255,255,255,0.6)",
    borderWidth: "1px",
    _hover: {
        borderColor: "blue.600", // Hover state
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderWidth: "1px",
    },
    _checked: {
        backgroundColor: "blue.500",
        borderColor: "blue.700",
    },
    _focus: {
        borderColor: "blue.700", // Focus state
        boxShadow: "0 0 0 1px var(--chakra-colors-blue-700)", // Focus ring
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderWidth: "1px",
    },
}

// theme.js
export default {
    "components": {
        Input: {
            baseStyle: {}, // Leave empty if not overriding base styles
            defaultProps: {
                variant: "custom", // Set a default variant for all inputs
            },
            variants: {
                custom: {
                    field: {
                        borderColor: "white", // Default border color
                        backgroundColor: "rgba(255,255,255,0.6)",
                        borderWidth: "1px",
                        _hover: {
                            borderColor: "blue.600", // Hover state
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderWidth: "1px",
                        },
                        _invalid: {
                            borderColor: "red.500",
                            boxShadow: "0 0 0 1px red.500"
                        },
                        _focus: {
                            borderColor: "blue.700", // Focus state
                            boxShadow: "0 0 0 1px var(--chakra-colors-blue-700)", // Focus ring
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            borderWidth: "1px",
                        },
                    }
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    backgroundColor: "white",
                    borderColor: "blue.600"
                },

            }
        },
    },
    "colors": {
        "green": {
            "50": "#ECF9F1",
            "100": "#C9EDD9",
            "200": "#A7E2C1",
            "300": "#84D6A9",
            "400": "#62CB90",
            "500": "#3FC078",
            "600": "#339960",
            "700": "#267348",
            "800": "#194D30",
            "900": "#0D2618"
        },
        "red": {
            "50": "#F9ECEC",
            "100": "#EDC9C9",
            "200": "#E2A7A7",
            "300": "#D68484",
            "400": "#CB6262",
            "500": "#C03F3F",
            "600": "#993333",
            "700": "#732626",
            "800": "#4D1919",
            "900": "#260D0D"
        },
        "yellow": {
            "50": "#FFFBE5",
            "100": "#FFF4B8",
            "200": "#FFED8A",
            "300": "#FFE55C",
            "400": "#FFDE2E",
            "500": "#FFD700",
            "600": "#CCAC00",
            "700": "#998100",
            "800": "#665600",
            "900": "#332B00"
        },
        "blue": {
            "50": "#E5F8FF",
            "100": "#B8ECFF",
            "200": "#8ADFFF",
            "300": "#5CD3FF",
            "400": "#2EC6FF",
            "500": "#00BAFF",
            "600": "#0095CC",
            "700": "#006F99",
            "800": "#004A66",
            "900": "#002533"
        },
        "pink": {
            "50": "#FFE5F2",
            "100": "#FFB8DB",
            "200": "#FF8AC4",
            "300": "#FF5CAD",
            "400": "#FF2E96",
            "500": "#FF0080",
            "600": "#CC0066",
            "700": "#99004D",
            "800": "#660033",
            "900": "#33001A"
        },
        "borderColors": "white"
    }
}