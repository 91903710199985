import { useCallback, useEffect, useRef, useState } from "react";
import useHeaderStore from '../../store/headerStore';
import './Modal.scss';

function Modal ({ children, onBackdropClick, active, dropShadow = true, closeIconClick, header }) {

    const [hidden, setHidden] = useState (true);
    const modalEl = useRef();
    const [setCloseIconVisible, setcloseIconClicked, setHeader] = useHeaderStore(state => [state.setCloseIconVisible, state.setcloseIconClicked, state.setHeader]);

    const _onBackdropClick = useCallback( function (e) {
        if (!modalEl.current.contains(e.target) && onBackdropClick) {
            onBackdropClick();
        }
    }, []);

    const transitionEnd = useCallback(function (e) {
        if (!active) setHidden(true);
    }, []);

    useEffect(() => {
        setHeader (header);
        if (active) {
            setHidden(false);
            setCloseIconVisible(true);
            setcloseIconClicked(onBackdropClick || closeIconClick);
        }
        return () => {
            setCloseIconVisible(false);
            setHeader(null)
        }
    }, [active]);


    return (
        <div className={`modal-backdrop${hidden ? ' hidden' : ''}`} onClick={_onBackdropClick}>
            <div className={`modal${active ? ' active' : ''} ${dropShadow ? 'shadow' : ''}`} ref={modalEl} onTransitionEnd={transitionEnd}>
                { children }
            </div>
        </div>
    )
}

export default Modal;
