import { faMaximize, faMinimize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import styles from './FullScreen.module.scss';

export default function FullScreen({aquariumRef}) {

    const [fullScreenMode, setFullScreenMode] = useState(document.fullscreenElement);

    const _onClick = useCallback(() => {
        if (document.fullscreenElement) {
            setFullScreenMode(false);
            document.exitFullscreen();
        }
        else {
            setFullScreenMode(true);
            aquariumRef.requestFullscreen();
        }
    });

    return (
        fullScreenMode ? 
            <FontAwesomeIcon icon={faMinimize} size="2x" onClick={_onClick} className={styles.fullScreen} />
            :
            <FontAwesomeIcon icon={faMaximize} size="2x" onClick={_onClick} className={styles.fullScreen} />
        
    )
}