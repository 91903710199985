import Header from "./Header/header";
import Menu from "./Menu/Menu";
import useMenuStore from "../../store/menuStore";

export default function MenuHeader({menuItems}) {
    const [visible, setVisible] = useMenuStore(state => [state.visible, state.setVisible]);
    return (
        <>
            <Header menuClicked={() => setVisible(visible => !visible)}/>
            <Menu displayed={visible} closeMenuClicked={() => setVisible(false)} >{menuItems}</Menu>
        </>
    );
}